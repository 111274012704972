<template>
  <v-row dense>
    <v-col cols="12" md="3">
      <v-text-field :disabled="isInvoiced == true" v-model="deadline.due_date" :label="(deadlineNumber) + '° Vencimento'" v-mask="['##/##/####']" dense
        outlined rounded hide-details />
    </v-col>
    <v-col cols="12" md="2">
      <v-text-field :disabled="isInvoiced == true" v-model.number="deadline.value" label="Valor" dense outlined rounded hide-details />
    </v-col>
    <v-col cols="12" md="1">
      <v-btn v-if="isInvoiced == false" @click="$emit('removeDeadline')" icon color="error">
        <v-icon small>fas fa-times</v-icon>
      </v-btn>
    </v-col>
  </v-row>
</template>

<script>
export default {
  props: {
    deadline: Object,
    deadlineNumber: Number,
    isInvoiced: {
      type: [Boolean, Number],
      default: false
    }
  },
  data: () => ({
    deadlineMenu: false,
    date: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
      .toISOString()
      .substr(0, 10),
  }),
};
</script>